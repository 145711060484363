import { combineReducers } from 'redux';
import auth from './auth';
import common from './common';
import home from './home';
import story from './story';

export default combineReducers({
    auth,
    common,
    home,
    story,
});