import React, { Component } from 'react';
import './Home.css';
import { connect } from 'react-redux';
import { getDownloadToDesk,getISInitFrame,getBoleKey} from '../actions/app';
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'
import Footer from '../components/Footer';
import lozad from 'lozad';
import util from '../services/Util';
// import api from '../services/Api';

const support = [
    {'name':'中国文物保护基金会。家谱专项基金委员会','img':'/image/support01.png'},
    {'name':'中国华侨公益基金会','img':'/image/support02.png'},
    {'name':'腾讯公益','img':'/image/support03.png'},
    {'name':'中华炎黄文化基金会','img':'/image/support04.png'},
    {'name':'浙江敦和慈善基金会','img':'/image/support05.png'},
]
const trust = [
    {'name':'中国国家图书馆','img':'/image/trust01.png'},
    {'name':'上海图书馆','img':'/image/trust02.png'},
    {'name':'美国犹他家谱协会','img':'/image/trust03.png'},
    {'name':'山西社科院','img':'/image/trust04.png'},
    {'name':'常州家谱馆','img':'/image/trust05.png'},
    {'name':'山东金鼎古籍','img':'/image/trust06.png'},
    {'name':'常州清砚家谱','img':'/image/trust07.png'},
    {'name':'安徽润方文化','img':'/image/trust08.png'},
];
const sliderData = [
    {'img':'/image/home-like01.png','infor':'中国历史文献灿若星河，史书、方志和家谱，是中华文明的瑰宝，流传不易，值得我们一起用心血去整理。','name':'张晓妮','from':'一名来自安徽的志愿者'},
    {'img':'/image/home-like02.png','infor':'上图有大量的家谱文献，一直致力于开放为社会公众服务。','name':'刘 玮','from':'上海图书馆副馆长'},
    {'img':'/image/home-like03.jpg','infor':'时光科技用大家的精力整理家谱上图有大量的家谱文献，一直致力于开放为社会公众服务。','name':'朱炳国','from':'常州家谱馆'},

]
const boleList = [
    {'name':'张无忌',img:'/image/home-like01.png','achievement':'57','bonus':'22.35'},
    {'name':'微微',img:'/image/home-like02.png','achievement':'57','bonus':'22.35'},
    {'name':'千里马',img:'/image/home-like03.jpg','achievement':'57','bonus':'22.35'},
    {'name':'张无忌22',img:'/image/home-like01.png','achievement':'57','bonus':'22.35'},
]
const mapStateToProps = state => ({
    btnUrl:state.common.btnUrl,
    downloadpath:state.common.downloadpath,    
});
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenVideo:false, 
            UserAgent:'',
        }; 
    } 
    gotoTry(){ //按钮跳转
        // console.log(111);
        window.location.href = this.props.btnUrl;
    }    
    render() {      
        return (
            <div className="homepage">
                <div className="home-banner"> 
                   {/* <div className="top-circle">
                       <img src="/image/top-circle.png" alt=""></img>
                   </div> */}
                   <div className="container">
                        <div className="banner-title">
                             <h2 style={{marginBottom:'35px'}}>涓涓细流 <React.Fragment></React.Fragment>汇成江河</h2>
                             {/* <h2>汇成江河</h2> */}
                             <p> 中国历史文献灿若星河，史书、方志和家谱，是中华文明的瑰宝，流传不易，值得我们一起用心血去整理。然而个人的力量毕竟有限，于是有了天天打字这个专业众包平台，集众人之力，建蚂蚁雄兵，一起推动文献的整理和再造。</p>
                             <p style={{margin:'20px 0'}}> 在这里，遇见最美的中文。在这里，遇见更多志同道合之士，一起让古籍重光，让文明绽放。</p>
                            <div className="banner-btn" style={{marginTop:'35px'}} onClick={()=>this.gotoTry()}>现在参与</div>
                        </div>
                        {/* <div className="home-person">
                            <div className="person-illust"></div>
                        </div> */}
                    </div> 
                    <div className="banner-btm"></div>
                    {/* <div className="triangle triangle--bottom triangle--gray">
                       <div className="banner-btm"></div>
                        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg"> 
                            <polygon points="2560 0 2560 100  0 100"></polygon>                             
                            <path fill="white" d="M 2560 164.648 V 66.4428 C 2560 66.4428 1198.64 30.0805 758.025 33.6272 C 317.412 37.1743 0 43.9593 0 43.9593 V 164.648 H 2560 Z">
                            </path>                            
                        </svg>
                   </div> */}
                </div>
                <div className="home-about process">
                    <div className="container">
                        <div className="home-content-title">
                            {/* <h6 className="title-tip">About Contest</h6> */}
                            <span className="title">熟悉产品运作流程</span>
                        </div>
                        <div className="home-phone-process" >
                            <img  src='/image/process-phone.png' alt=""></img>
                        </div>                    
                        <ul className="home-process">
                            <li>
                                <div>
                                    <img src='/image/icon/process01.png' alt=""></img>
                                    <p>注册登录</p>
                                </div>
                            </li>
                            <li className="process-arrow"></li>
                            <li>
                                <div>
                                    <img src='/image/icon/process02.png' alt=""></img>
                                    <p>熟悉录入规则</p>
                                </div>
                            </li>
                            <li className="process-arrow"></li>
                            <li>
                                <div>
                                    <img src='/image/icon/process03.png' alt=""></img>
                                    <p>领取任务<br />桌面端录入</p>
                                </div>
                            </li>
                            <li className="process-arrow"></li>
                            <li>
                                <div>
                                    <img src='/image/icon/process04.png' alt=""></img>
                                    <p>提交录入<br />校验修改确认</p>
                                </div>
                            </li> 
                            <li className="process-arrow"></li>
                            <li>
                                <div>
                                    <img src='/image/icon/process05.png' alt=""></img>
                                    <p>申请结算<br />领取薪酬</p>
                                </div>
                            </li>                            
                        </ul>
                    </div>                                                       
                </div> 
                <div className="home-about ranking">
                    <div className="home-ranking-box">
                    <div className="container">
                        <div className="home-content-title">                         
                            <span className="title">累计榜单</span>
                        </div>
                        <div className="ranking-box">
                            <div className="ranking-left">
                                <div className="ranking-content">
                                    <div className="bole-table">
                                        <div className="bole-table-top">
                                            <h4><img src="/image/icon/home-ranking01.png" alt=""></img>积分排名</h4> 
                                            <ul>
                                            {
                                                ['排 名','成员','积分'].map((item,index)=>{
                                                    return(
                                                        <li key={index}>{item}</li>
                                                    )
                                                })
                                            }
                                            </ul>                               
                                        </div>
                                        <div className="bole-table-content">
                                            <ul>
                                                {
                                                    boleList.map((item,index)=>{
                                                        return(
                                                            <li key={index}>
                                                                <div>
                                                                    <span>{index < 3 ? (index === 0 ? <img src="/image/icon/bole-01.png" alt=''></img>:(index === 1 ? <img src="/image/icon/bole-02.png" alt=''></img>:<img src="/image/icon/bole-03.png" alt=''></img>)) 
                                                                    :index + 1 }</span>
                                                                    <span>
                                                                        {
                                                                            item.img ? 
                                                                            <img src={item.img} alt=''></img>:
                                                                            <img src="/image/01.png" alt=''></img>
                                                                        }
                                                                        {
                                                                            item.name ?  
                                                                            <p>{item.name}</p> :
                                                                            ''                                                               
                                                                        }                                                         
                                                                    </span>
                                                                    <span>{item.achievement ? item.achievement :'0'}</span>                                                                  
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul> 
                                        </div>                         
                                    </div>
                                </div>
                            </div>
                            <div className="ranking-right">
                                <div className="ranking-content">
                                    <div className="bole-table">
                                        <div className="bole-table-top">
                                            <h4><img src="/image/icon/home-ranking02.png" alt=""></img>邀请新人排名</h4> 
                                            <ul>
                                            {
                                                ['排 名','成员','积分'].map((item,index)=>{
                                                    return(
                                                        <li key={index}>{item}</li>
                                                    )
                                                })
                                            }
                                            </ul>                               
                                        </div>
                                        <div className="bole-table-content">
                                            <ul>
                                                {
                                                    boleList.map((item,index)=>{
                                                        return(
                                                            <li key={index}>
                                                                <div>
                                                                    <span>{index < 3 ? (index === 0 ? <img src="/image/icon/bole-01.png" alt=''></img>:(index === 1 ? <img src="/image/icon/bole-02.png" alt=''></img>:<img src="/image/icon/bole-03.png" alt=''></img>)) 
                                                                    :index + 1 }</span>
                                                                    <span>
                                                                        {
                                                                            item.img ? 
                                                                            <img src={item.img} alt=''></img>:
                                                                            <img src="/image/01.png" alt=''></img>
                                                                        }
                                                                        {
                                                                            item.name ?  
                                                                            <p>{item.name}</p> :''                                                                                                                                     
                                                                        }                                                         
                                                                    </span>
                                                                    <span>{item.achievement ? item.achievement :'0'}</span>                                                                   
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul> 
                                        </div>                         
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div className="banner-btn join-btn" onClick={()=>this.gotoTry()}>现在参与</div>
                     </div> 
                    </div>                   
                </div>
                <div className="home-about recording">
                    <div className="container" style={{textAlign:'center'}}>
                        <div className="home-video">
                            <div className="home-content-title">                      
                                <span className="title">了解如何录入</span>
                            </div>
                            <div className="video">
                                {
                                    !this.state.isOpenVideo ? 
                                    <div className="video-bg">
                                      <span className="video-icon" onClick={()=>this.setState({isOpenVideo:true})}>
                                          <img src="/image/icon/video-icon.svg" alt=""></img>
                                     </span>                                     
                                   </div> :                               
                                   <video autoplay="" loop="" src="/image/linkpage.mp4" controls="controls" style={{ width: '100%' }}></video>
                                }                            
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-about like">                
                    <div className="container">
                        <div className="home-content-title">                          
                            <span className="title">喜欢使用天天打字</span>
                        </div>
                        <div className="home-slider">
                            <div className="swiper-container">
                                <div className="swiper-wrapper">                                                            
                                    {
                                        sliderData.map((item,index)=>{
                                            return(
                                                <div key={index} className="swiper-slide">
                                                   <div className="swiper-item">
                                                        {/* <div className="slide-ava"><img src={item.img} alt=''></img></div> */}
                                                        <div className="slide-ava" style={{backgroundImage:'url('+item.img+')'}}></div>
                                                        <div className="intro">{item.infor}</div>
                                                        <h4>{item.name}</h4>
                                                        <p className="h-form">{item.from}</p>
                                                   </div>
                                                </div>                                                
                                            )
                                        })
                                    } 
                                </div>
                                <div className='swiper-pagination'></div>
                    {/*             <div className='swiper-button-warp'>
                                    <div className="swiper-button-next"></div>
                                    <div className="swiper-button-prev"></div>
                                </div> */}
                            </div>
                        </div>
                    </div>                                                      
                </div> 
                <div className="home-about volunteer" >                  
                    <div className="container home-volunteer">
                        <div className="home-content-title">                          
                            <span className="title">文献整理志愿者？</span>
                        </div>
                        <div className="banner-btn join-btn" onClick={()=>this.gotoTry()}>现在试试</div>
                   </div>
                    <img src="/image/volunteer.svg" alt='' style={{width:'100%'}}></img> 
                    {/* <img  className ='lozad' data-src='/image/02.png' alt="" ></img>     */}                                                                                         
                </div>              
                <div className="home-about support">
                    <div className="container">
                        <div className="home-content-title">                          
                            <span className="title">特别支持</span>
                        </div>
                        <div className="support-list">
                             <ul>
                                 {
                                     support.map((item,index)=>{
                                         return(
                                             <li key={index} style={{marginBottom:( window.innerWidth > 768 ? (support.length > 5 ? '45px' :'0'):'15px')}}>
                                                 <div className="list-item">
                                                     <div className="img-box">
                                                         <img src={item.img} alt=''></img>
                                                     </div>
                                                     {
                                                       item.name.indexOf('。') !== -1 ?
                                                       <p>
                                                           {
                                                               item.name.split('。').map((name,index2)=>{
                                                                   return(
                                                                       <span key={index2}>{name}</span>
                                                                   )
                                                               })
                                                           }
                                                       </p>:
                                                       <p>{item.name}</p>                                                      
                                                     }                                                   
                                                 </div>
                                             </li>
                                         )
                                     })
                                 }                                
                             </ul>
                        </div>                         
                    </div>                                                       
                </div> 
                <div className="home-about support">
                    <div className="container">
                        <div className="home-content-title">                          
                            <span className="title">信任我们</span>
                        </div>
                        <div className="support-list">
                             <ul>
                                 {
                                     trust.map((item,index)=>{
                                         return(
                                             <li key={index} style={{marginBottom:( window.innerWidth > 768 ? (trust.length > 5 ? '45px' :'0'):'15px')}}>
                                                 <div className="list-item">
                                                      <div className="img-box">
                                                         <img src={item.img} alt=''></img>
                                                      </div>
                                                     <p>{item.name}</p>
                                                 </div>
                                             </li>
                                         )
                                     })
                                 }                               
                             </ul>
                        </div>                         
                    </div>                                                       
                </div>
                 {/* <div className="download-desk" onClick={()=>this.downloadToDesks()} >
                     <img src="/image/download-desk.png" alt=''></img>
                 </div>                     */}
                <Footer />
            </div>
        );
    };

    componentDidMount() {
        new Swiper('.swiper-container', {
            autoplay:{      //自动播放，注意：直接给autoplay:true的话，在点击之后不能再自动播放了
                delay: 2500,
                disableOnInteraction: false,    //户操作swiper之后，是否禁止autoplay。默认为true：停止。
            }, 
            // watchOverflow: true,
            slidesPerView : 1,
            slidesPerGroup : 1,
            spaceBetween : 0,                  
            // centerInsufficientSlides: true,  //开启这个参数，当slides的总数小于slidesPerView时，slides居中         
            breakpointsInverse: true, //breakpoints将以反方向计算，类似于media only screen and (min-width: 480px)              
            breakpoints: {
                769: {
                    slidesPerView:3,
                    slidesPerGroup:3,
                    spaceBetween: 10, 
                    loopFillGroupWithBlank: true,                                       
                },              
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,    // 允许点击跳转
            },         
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },          
        });
    //   if(window.localStorage.getItem('isIniframe')){
    //      window.localStorage.removeItem("isIniframe");
    //   } 
      this.props.getISInitFrame(2);  // isIniframe=2 外部打开 isIniframe=1调用打开，头部隐藏   
      let inviterKey = util.common.getQueryString('inviterKey') || '';//分享-伯乐的key
      this.props.getBoleKey(inviterKey);
    }
    componentDidUpdate(prevPros) {
        // lazy loads elements with default selector as '.lozad'    
        // img 懒加载   
        const observer = lozad();
        observer.observe();
    }    
}

export default connect(
    mapStateToProps,
    { getDownloadToDesk,getISInitFrame,getBoleKey},
)(Home);