import React, { Component } from 'react';
import './Home.css';
import { connect } from 'react-redux';
import Footer from '../components/Footer';


class Teasing extends Component {
   
    render() {      
        return (
            <div className="homepage">
                 <div className="home-banner bolePrize">
                    <div className="container"></div>
                    <div className="banner-btm"></div>
                 </div>
                 <div className="common-content layout">
                     <div className="container">
                         神吐槽
                     </div>
                 </div>     
                <Footer />
            </div>
        );
    };
    componentDidMount() {
        
    }
   
}

export default connect( 
   
)(Teasing);