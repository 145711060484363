import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs,Statistic } from 'antd';
import { getISInitFrame } from '../actions/app';
import './RankingPrize.css';
import Footer from '../components/Footer';
import CountUp from 'react-countup'; //数字动态添加
import util from '../services/Util';
const TabPane = Tabs.TabPane;
const { Countdown } = Statistic;
const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30;
const countUpProps = {
    start: 0,
    duration: 5,
    useEasing: true,
    useGrouping: true,
    separator: ',',
};
const myranking = [
    {'num':'1055','percent':'87','exceed':'1055','unlist':'233','onlist':'2','img':'/image/icon/ranking1.svg'},
    {'num':'255','percent':'69','exceed':'1055','unlist':'233','onlist':'2','img':'/image/icon/ranking2.svg'},
    {'num':'22.33','percent':'52','exceed':'1055','unlist':'233','onlist':'2','img':'/image/icon/ranking3.svg'},
    {'num':'223.00','percent':'37','exceed':'1055','unlist':'233','onlist':'2','img':'/image/icon/ranking4.svg'}
]
const tabPrize = [
    '/image/icon/ranking-table-01.svg','/image/icon/ranking-table-02.svg','/image/icon/ranking-table-03.svg','/image/icon/ranking-table-04.svg'
]
const rankingList = [   
    {
        'title':'今日快手榜','info':'录入文字数排名','content':[
            {'name':'张无忌',img:'/image/home-like01.png','achievement':'57','bonus':'22.35'},
            {'name':'微微',img:'/image/home-like02.png','achievement':'57','bonus':'22.35'},
            {'name':'千里马',img:'/image/home-like03.jpg','achievement':'57','bonus':'22.35'},
            {'name':'张无忌22',img:'/image/home-like01.png','achievement':'57','bonus':'22.35'},]
    },
    {
        'title':'今日有谱榜','info':'录入节点数排名','content':[
            {'name':'张无忌',img:'/image/home-like01.png','achievement':'57','bonus':'22.35'},
            {'name':'微微',img:'/image/home-like02.png','achievement':'57','bonus':'22.35'},
            {'name':'千里马',img:'/image/home-like03.jpg','achievement':'57','bonus':'22.35'},
         ]
    },
    {
        'title':'今日蜜蜂榜','info':'工作市场排名','content':[
            {'name':'张无忌',img:'/image/home-like01.png','achievement':'57','bonus':'22.35'},
            {'name':'微微',img:'/image/home-like02.png','achievement':'57','bonus':'22.35'},           
         ]
    },
    {
        'title':'贡献奖','info':'获取积分排名','content':[
            {'name':'张无忌',img:'/image/home-like01.png','achievement':'57','bonus':'22.35'},           
         ]
    }
]
const mapStateToProps = state => ({
    isIniframe:state.home.isIniframe,   
});
class RankingPrize extends Component {   
    constructor(props) {
        super(props);
        this.state = {         
            tabsKey:0,  
            day: [],
            hour: [],
            minute: [],
            second:[]               
        };
    }
    changgAbouts(key){     
        this.setState({tabsKey:key-1})
    }
    render() {         
        const {isIniframe} = this.props;
        return (
            <div className="homepage">
                 <div className="home-banner bolePrize" style={{paddingTop:(isIniframe === '1' ? '60px':'120px') }}>                   
                    <div className="container">
                        <div className="banner-content">                            
                            <div><img src='/image/banner-ranking-title.png' alt=""></img></div>
                            <div className="ranking-time">
                                <p>下次开奖时间</p>
                                <Countdown title="" value={deadline} format="D 天 H 时 m 分 s 秒" />
                                {/* <p>
                                    <span className="time-bg">{this.state.day}</span>天
                                    {
                                    this.state.hour.map((h,index1)=>{
                                        return(
                                            <span className="time-bg" key={index1}>{h}</span>
                                        )
                                    }) 
                                    }时
                                    {
                                    this.state.minute.map((m,index2)=>{
                                        return(
                                            <span className="time-bg" key={index2}>{m}</span>
                                        )
                                    }) 
                                    }分
                                    {
                                    this.state.second.map((s,index3)=>{
                                        return(
                                            <span className="time-bg" key={index3}>{s}</span>
                                        )
                                    }) 
                                    }秒                             
                                </p>                                */}
                                <p style={{paddingTop:'10px'}}>上榜按名次分享<span>10991</span>奖励</p>
                            </div>                            
                        </div>
                    </div>
                    <div className="banner-btm"></div>
                    <div className="banner-btm2"><img src="/image/banner-ranking.svg" alt="'"></img></div>
                 </div>
                 <div className="common-content">
                     <div className="layout rankingprize">
                         <div className="container">
                             <div className="rankingprize-box">
                                <Tabs defaultActiveKey="1" onChange={(key) => this.changgAbouts(key)}>
                                    <TabPane tab="八月" key="1">
                                        <div className="rankingprize-content">
                                             <p className="rankingprize-p title">我的本月排行榜</p>                                             
                                             <div className="rankingprize-card">
                                                 <ul>
                                                     {
                                                         myranking.map((item,index)=>{
                                                             return(
                                                                 <li key={index}>
                                                                     <div className="rankingprize-card-box">
                                                                         <div className="rankingprize-card-top">
                                                                             <img src={item.img} alt=''></img>
                                                                         </div>
                                                                         <div className="rankingprize-card-intro">                                                                            
                                                                             {
                                                                                 index === 0 ? 
                                                                                 <p>录入<em>{item.num}</em>个文字</p>:null
                                                                             }
                                                                             {
                                                                                 index === 1 ? 
                                                                                 <p>录入<em>{item.num}</em>位人物节点</p>:null
                                                                             }
                                                                             {
                                                                                 index === 2 ? 
                                                                                 <p>工作<em>{item.num}</em>小时</p>:null
                                                                             }
                                                                             {
                                                                                 index === 3 ? 
                                                                                 <p>获取<em>{item.num}</em>积分</p>:null
                                                                             }
                                                                             <h4>
                                                                                 <CountUp
                                                                                    end={item.percent}
                                                                                    suffix="%"
                                                                                    {...countUpProps}
                                                                                 />
                                                                             </h4>
                                                                             <p>超过<em>{item.exceed}</em>名网友</p>
                                                                             <p>离上榜<em>{item.unlist}</em>名</p>
                                                                             <p>已上榜<em>{item.onlist}</em>次</p>
                                                                         </div>
                                                                     </div>
                                                                 </li>
                                                             )
                                                         })
                                                     }                                                   
                                                 </ul>
                                             </div>
                                             <div className="rankingprize-table">
                                                <p className="rankingprize-p title" style={{padding:'0px 0 25px'}}>平台月度排行榜</p>
                                                <div className="rankingprize-table-box">
                                                    <ul className="rankingprize-table-ul">
                                                        {
                                                            rankingList.map((item,index)=>{
                                                                return(
                                                                    <li key={index}>
                                                                        <div className="rankingprize-table-item">
                                                                           <div className="bole-table">
                                                                                <div className="bole-table-top">                                                                                   
                                                                                    <h4 style={{margin:'0'}}><img src={tabPrize[index]} alt=""></img>{item.title}</h4>
                                                                                    <p style={{marginBottom:'15px',letterSpacing:'1px'}}>{item.info}</p> 
                                                                                    <ul>
                                                                                    {
                                                                                        ['排 名','成员','积分'].map((item,index)=>{
                                                                                            return(
                                                                                                <li key={index}>{item}</li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    </ul>                               
                                                                                </div>
                                                                                <div className="bole-table-content">
                                                                                    <ul>
                                                                                        {
                                                                                        item.content.map((item2,index2)=>{
                                                                                                return(
                                                                                                    <li key={index2}>
                                                                                                        <div>
                                                                                                            <span>{index2 < 3 ? (index2 === 0 ? <img src="/image/icon/bole-01.png" alt=''></img>:(index2 === 1 ? <img src="/image/icon/bole-02.png" alt=''></img>:<img src="/image/icon/bole-03.png" alt=''></img>)) 
                                                                                                            :index2 + 1 }</span>
                                                                                                            <span>
                                                                                                                {
                                                                                                                    item2.img ? 
                                                                                                                    <i className="table-ava" 
                                                                                                                         style={{backgroundImage:'url('+item2.img+')'}}
                                                                                                                    ></i>:                                                                                                                    
                                                                                                                    <i className="table-ava" 
                                                                                                                         style={{backgroundImage:'url(/image/01.png)'}}>                                                                                                                            
                                                                                                                     </i>
                                                                                                                }                                                                                                               
                                                                                                                {/* {
                                                                                                                    item2.img ? 
                                                                                                                    <img src={item2.img} alt=''></img>:
                                                                                                                    <img src="/image/01.png" alt=''></img>
                                                                                                                } */}
                                                                                                                {
                                                                                                                    item2.name ?  
                                                                                                                    <p>{item2.name}</p> : ''                                                                                                                                                                              
                                                                                                                }                                                         
                                                                                                            </span>  
                                                                                                            <span>{item2.achievement ? item2.achievement :'0'}</span>                                                                                                              
                                                                                                        </div>
                                                                                                    </li>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </ul> 
                                                                                </div>                         
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                             </div>
                                        </div>                               
                                    </TabPane>
                                    <TabPane tab="七月" key="2"> 
                                      暂无数据                              
                                    </TabPane>
                                    <TabPane tab="六月" key="3">
                                      暂无数据                                
                                    </TabPane>
                                    <TabPane tab="五月" key="4"> 
                                      暂无数据                               
                                    </TabPane>
                                </Tabs>
                             </div>
                         </div>
                     </div>
                 </div>     
                <Footer />
            </div>
        );
    };
    componentDidMount() {
        let isIniframe = util.common.getQueryString('isIniframe') || '';        
        this.props.getISInitFrame(isIniframe);
        // if(this.props.endTime){
        //     let endTime = this.props.endTime;
        //     this.countFun(endTime);
        //   }        
    }
     //组件卸载取消倒计时
    // componentWillUnmount(){
    //     clearInterval(this.timer);
    // }
    // countFun = (time) => {
    //     let end_time = new Date(time).getTime(),
    //     sys_second = (end_time - new Date().getTime());
    //     this.timer = setInterval(() => {
    //     //防止倒计时出现负数
    //       if (sys_second > 1000) {
    //         sys_second -= 1000;
    //         let day = Math.floor((sys_second / 1000 / 3600) / 24);
    //         let hour = Math.floor((sys_second / 1000 / 3600) % 24);
    //         let minute = Math.floor((sys_second / 1000 / 60) % 60);
    //         let second = Math.floor(sys_second / 1000 % 60);
    //         hour =  hour < 10 ? "0" + hour : hour;
    //         minute = minute < 10 ? "0" + minute : minute;
    //         second = second < 10 ? "0" + second : second;
          
            
    //         let hours = hour.toString();
    //         let minutes = minute.toString();
    //         let seconds = second.toString();
    //         let h= hours.split(''); 
    //         let m = minutes.split('');
    //         let s = seconds.split('');
    //         // console.log('h',h);
    //         this.setState({
    //           day:day,
    //           hour:h,
    //           minute:m,
    //           second:s
    //         })
    //       } else {
    //         clearInterval(this.timer);
    //         //倒计时结束时触发父组件的方法
    //         //this.props.timeEnd();
    //       }
    //     }, 1000);
    //   }
   
}

export default connect(  
    mapStateToProps, 
    {getISInitFrame} 
)(RankingPrize);