import { ISINIFRAME,GETBOLEKEY,KNOWLEDGEKEY } from '../actions/app';
const defaultState = {
    isIniframe:2,
    inviterKey:'',
    knowLedgeKey:[],
};

const auth = (state = defaultState, action) => {
    switch (action.type) { 
        case ISINIFRAME://isIniframe=2 外部打开 isIniframe=1内部打开，头部隐藏
            return {
                ...state,
                isIniframe:action.isIniframe,                
            }
        case GETBOLEKEY://分享-伯乐的key
            return {
                ...state,
                inviterKey:action.inviterKey,                
            }
        case KNOWLEDGEKEY://获取知识点的key
        return {
            ...state,
            knowLedgeKey:action.knowLedgeKey,                
        }
        default:
            return state;
    }
};

export default auth;
