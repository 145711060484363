const common = {
    // 判断当前是否在小程序内
    isMiniProgram() {
        return window.__wxjs_environment === 'miniprogram';
    },

    // 判断当前是否是手机端
    isMobile() {
        return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    },
    // 获取url参数
    getQueryString(name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return null;
    },
    //识别电脑系统并返回
    getUserAgent:function(){
        var agent = navigator.userAgent.toLowerCase();
        var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
        if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
            return 'windows';
        }
        if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
            return 'windows';
          }
        if(isMac){
            return 'mac';
        }
    }
}
export default {
    common
};