import api from '../services/Api';

// common
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const DOWNLOAD_TODESK = 'DOWNLOAD_TODESK';


export function getDownloadToDesk(systemName){
    let request = api.download.downloadToDesk(systemName);
    return { type: DOWNLOAD_TODESK, payload: request }
}

// auth
export const GET_USER_INFO = 'GET_USER_INFO';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';

//home
export const ISINIFRAME = 'ISINIFRAME';
export const GETBOLEKEY = 'GETBOLEKEY';
export const KNOWLEDGEKEY = 'KNOWLEDGEKEY';

export function logout(history) {
    history.push('/');
    return { type: LOGOUT }
}
export function getUserInfo(token) {   
    if (token) {
        api.setToken(token);
    }
    let request = api.auth.getUserFullInfo(token);
    return { type: GET_USER_INFO, payload: request }
}

export function getISInitFrame(isIniframe) {   
    return { type: ISINIFRAME, isIniframe: isIniframe }
}
export function getBoleKey(inviterKey) {   
    return { type: GETBOLEKEY, inviterKey: inviterKey }
}
// 获取知识点的key
export function getKnowledgeKey(knowLedgeKey) {   
    return { type: KNOWLEDGEKEY, knowLedgeKey: knowLedgeKey }
}

//theme
export const GET_STORY_THEMES = 'GET_STORY_THEMES';
export const SELECT_STORY_THEME = 'SELECT_STORY_THEME';

export function getStoryThemes(token) {
    let request = api.story.getStoryTheme(token);
    return { type: GET_STORY_THEMES, payload: request }
}
export function selectTheme(theme) {
    return { type: SELECT_STORY_THEME, theme: theme }
}