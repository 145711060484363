import React, { Component } from 'react';
import './Home.css';
import { connect } from 'react-redux';
// import { message } from 'antd';
import Footer from '../components/Footer';
import './Download.css';
// import util from '../services/Util';
import api from '../services/Api';

const mapStateToProps = state => ({
    downloadpath:state.common.downloadpath,   
});

class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weburl:'https://ttdazidata.qingtime.cn',           
        }; 
    }
      //浏览器下载桌面天天打字
      async downloadToDesks(type){          
        // let name = 'ttdazi.exe';   
        //const { downloadpath } = this.props;
        const {weburl} = this.state;
        // let device = util.common.getUserAgent();             
            let res = await api.download.downloadToDesk(type);
            if(res.msg === 'OK'){               
                window.open(weburl + '/' + res.appPath);
                // let appPath = res.appPath.split('/')[2];
                // window.open(downloadpath + '/' + appPath);                      
            //     fetch(downloadpath + res.appPath).then(res => res.blob().then(blob => {
            //     var a = document.createElement('a');
            //     var url = window.URL.createObjectURL(blob);
            //     var filename = name;            
            //     a.href = url;
            //     a.download = filename;
            //     document.body.appendChild(a); //兼容火狐
            //     a.click();                    
            //     window.URL.revokeObjectURL(url);
            //     setTimeout(function(){ //兼容火狐
            //         document.body.removeChild(a);                
            //     },1000); 
            // }))            
        }  
    //   else{
    //      message.error('请选择适合您系统的安装包')
    //  }      
    }
    render() {      
        return (
            <div className="homepage">
                 {/* <div className="home-banner bolePrize"> */}
                 <div className="home-banner download-banner">
                    <div className="container">
                        <div className="banner-title-top">
                            <h2>天天打字桌面端</h2>
                            <p>打字录入  更快更简单</p>
                            <p>免费下载</p>
                            <p><img src="/image/smile.png" alt=''></img></p>
                        </div>
                    </div>
                    {/* <div className="banner-btm"></div> */}
                 </div>
                 <div className="download-content">
                    <div className="container">
                        <div className="download-bg"><img src="/image/download-bg.png" alt=''></img></div>
                    </div>
                 </div>
                 <div className="download-content layout download-btn-body">
                     <div className="container">
                            <div className="download-btn-box">
                                <div className="download-btn-box-left" >                                    
                                    <div className="download-btn" onClick={()=>this.downloadToDesks('mac')}>
                                        <span><img src='/image/icon/mac.svg' alt=''></img></span>
                                        <div className="download-btn-desc">
                                            <h3>Mac<span>版本</span></h3>
                                            <p>录入节点专用</p>
                                        </div>
                                    </div>  
                                    <p className="download-btn-tip">运行环境：Win7+，下载前请先退出360程序</p>                                                                      
                                </div> 
                                <div className="download-btn-box-left">
                                    <div className="download-btn" onClick={()=>this.downloadToDesks('windows')}>
                                        <span><img src='/image/icon/windows.svg' alt=''></img></span>
                                        <div className="download-btn-desc">
                                            <h3>Windows<span>版本</span></h3>
                                            <p>录入节点专用</p>
                                        </div>
                                    </div> 
                                    <p className="download-btn-tip">运行环境：Win7+，下载前请先退出360程序</p>                                      
                                </div>                               
                            </div>                
                            {/* <p style={{padding:'10px 0',color:'red'}}>*下载前请先退出360程序！</p> */}                      
                     </div>
                 </div>     
                <Footer />
            </div>
        );
    };
    componentDidMount() {
        
    }
   
}

export default connect( 
    mapStateToProps
)(Download);