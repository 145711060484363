import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip,message } from 'antd';
import { getISInitFrame } from '../actions/app';
import './BolePrize.css';
import Footer from '../components/Footer';
import {CopyToClipboard} from 'react-copy-to-clipboard';//复制链接
import CountUp from 'react-countup'; //数字动态添加
import util from '../services/Util';

const boleList = [
    {'name':'张无忌',img:'/image/home-like01.png','achievement':'57','bonus':'22.35'},
    {'name':'微微',img:'/image/home-like02.png','achievement':'57','bonus':'22.35'},
    {'name':'千里马',img:'/image/home-like03.jpg','achievement':'57','bonus':'22.35'},
    {'name':'张无忌22',img:'/image/home-like01.png','achievement':'57','bonus':'22.35'},
];
const mapStateToProps = state => ({    
    isIniframe:state.home.isIniframe, 
});
const QRCode = require('qrcode.react');
class BolePrize extends Component { 
    constructor(props) {
        super(props);
        this.state = {         
            width:window.innerWidth, 
            inviteUrl:'https://ttdazi.qingtime.cn/' ,
            copied: false,   
            shareUrl:'https://ttdazi.qingtime.cn/',          
            billUrl:'/image/bole-bill01.png',
            userkey:'',
           
        };
    }    
    // 分享到第三方
    shareToThird(type){
        // 'weibo','tqq','qzone'
        const {shareUrl} = this.state; 
        let weburl = 'https://ttdazi.qingtime.cn/';      
        let img = '/image/bole-bill01.png';
        let uri = '';
        let inviterKey = window.localStorage.getItem('userKey') ? window.localStorage.getItem('userKey') :'';    
        let url = shareUrl+'?inviterKey='+ inviterKey;  
        img = weburl+'image/bole-bill02.png';      
        if(window.localStorage.getItem('token') && window.localStorage.getItem('token') !=='underfind'){
            // if(width > 767){
            //     img = weburl+'image/bole-bill01.png';  
            // } else{
            //     img = weburl+'image/bole-bill02.png';
            // }
            switch(type){
            case 'weibo':
                    uri = 'http://service.weibo.com/share/share.php?url=' + encodeURI(shareUrl) +'?inviterKey='+inviterKey+'&title=天天打字'+'&pic='+img+'&searchPic=true&appkey=1343713053';
                    window.open(uri);
            break;
            case 'tqq':
                    uri = 'https://connect.qq.com/widget/shareqq/index.html?url='+encodeURI(shareUrl)+'?inviterKey='+inviterKey+'&desc=涓涓细流，汇成江河'+'&title=天天打字'+'&pics='+img+'&style=203'+'&width=19'+'&height=22';
                    window.open(uri);
            break;
            case 'qzone':
                    uri = 'https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url='+ encodeURIComponent(url) +'&title=天天打字'+'&pics='+img;
                    window.open(uri);
            break;
            } 
        } else{
            message.warning('未登录，请先登录');
        }    
    }
    //复制粘贴
    copyShareUrl(){
        if(window.localStorage.getItem('token') && window.localStorage.getItem('TOKEN') !== 'undefined'){   
            this.setState({copied: true});
            message.success('复制成功')                       
        } else{   
            message.warning('未登录，请先登录');      
            this.setState({copied: false});             
            return;
        }
    }
    //把canvas转成image，并下载   
    changeCanvasToPic=()=>{
        var Qr=document.getElementById('qrid');  
        let image = new Image();
        image.src = Qr.toDataURL("image/png");
        var a_link=document.getElementById('aId');
        a_link.href=image.src;
        // a_link.download=id;//好像这一行可以不写，，你可以尝试一下
    }

    render() {
        const {inviteUrl,userkey} = this.state;
        const {isIniframe} = this.props;
        // 96707
        let userKey = window.localStorage.getItem('userKey') ? window.localStorage.getItem('userKey') :'';
        let copyUrl = inviteUrl+'?userKey='+userKey+'desc=涓涓细流，汇成江河';
        console.log('userkey',userkey);
        return (
            <div className="homepage">
                 <div className="home-banner bolePrize" style={{paddingTop:(isIniframe === '1' ? '60px':'120px') }}>
                    <div className="horse">
                        <img src="/image/horse.png" alt=""></img>
                    </div>
                    <div className="container">
                        <div className="banner-content">
                            <div className="banner-title-top">
                                <p>邀请更多高手</p>
                                <p>一起加入时光文献整理众包平台</p>
                            </div>
                            <div><img src='/image/banner-title.png' alt=""></img></div>
                            <div>
                                <p>好的平台，值得大家一起拥有。下一位千里马，正在等待伯乐。</p>
                                <p>邀请更多人参与吧，让古籍重光，文明再现！</p>
                            </div>                          
                            {/* <CopyToClipboard text={this.state.inviteUrl}
                                // onCopy={() =>this.setState({copied: true}) } 
                                onCopy={() => {
                                    this.setState({copied: true});
                                    message.success('复制成功')
                                }}                                                          
                                // onSuccess={() => message.error('复制成功')}
                                // onError={() => message.error('复试失败，该浏览器不支持自动复制！')}
                                >
                                <div className="banner-btn copy-btn">
                                <img src="/image/icon/copy-icon.svg" alt=""></img>复制邀请链接</div>
                            </CopyToClipboard> */}

                        </div>
                    </div>
                    <div className="banner-btm"></div>
                    <div className="banner-btm2"><img src="/image/banner-prize.png" alt="'"></img></div>
                 </div>
                 <div className="common-content">
                 <div className="layout bolePrize">
                        <div className="container">
                            <div className="home-content-title">                            
                                <span className="title">分享海报</span>                              
                            </div>                           
                            <div className="bill-box clearfix">
                                <div className="bill-left">   
                                    <span className="qr-code">
                                       {/* <QRCode id='qrCode'
                                            value={inviteUrl+'?inviterKey='+userkey}  
                                            size={(this.state.width > 768 ? 178:114)}                                             
                                            onClick={()=>this.changeCanvasToPic() }
                                            />    */}
                                            <a download id='aId' title="点我下载！" style={{display:'inline-block'}}>
                                               <QRCode id='qrid' 
                                                  value={inviteUrl+'?inviterKey='+userkey} 
                                                  onClick={()=>this.changeCanvasToPic()}  
                                                  size={(this.state.width > 768 ? 178:114)}  /> 
                                            </a>
                                    </span>                                          
                                                                                                        
                                    <img src= '/image/bole-bill02.png' alt=''></img> 
                                    {
                                       this.state.width > 767 ? null :
                                       <p className="savaImg">* 长按图片可保存到相册</p> 
                                    }                                    
                                </div>
                                <div className="bill-right">
                                    <ul>
                                        {
                                            // ['weibo','tqq','qzone'].map((item,index)=>{
                                            [{name:'weibo',tip:'微博'},{name:'tqq',tip:'QQ好友'},{name:'qzone',tip:'QQ空间'}].map((item,index)=>{
                                                return(                                                    
                                                    <Tooltip placement="right" title={item.tip} key={index}>                                                        
                                                        <li onClick={()=>this.shareToThird(item.name)}></li>
                                                    </Tooltip>
                                                )                                                
                                            })
                                        }                            
                                        <Tooltip placement="right" title='复制链接'>
                                            <li> 
                                              <CopyToClipboard text={copyUrl}
                                                onCopy={() => this.copyShareUrl()}>
                                               <img src="/image/icon/copy-icon.png" alt="" />                                                       
                                            </CopyToClipboard></li>
                                        </Tooltip>                                                                      
                                    </ul>
                                  
                                </div>                                
                            </div>
                            {/* <div className="bill-btn">
                                <a href={this.state.width > 767 ? 'http://exp.qingtime.cn/bole-bill01.png':'http://exp.qingtime.cn/bole-bill02.png'} download="宣传海报.jpg">
                                    <img src='/image/icon/download.svg' alt=''></img>下载海报</a>
                            </div> */}
                            {
                              this.state.width > 767 ?
                              <div className="bill-btn" 
                                  onClick={()=>this.downloadImg(this.state.billUrl,'invite-bill.png')}
                                >
                                <img src='/image/icon/download.svg' alt=''></img>下载海报
                             </div> :null
                            }
                           
                        </div>
                    </div> 
                     <div className="layout sharebill">                     
                        <div className="container">
                            <div className="home-content-title">                            
                                <span className="title">平台伯乐</span>
                                <p className="title-intro">对方每次提交成果，你都将获得 <em>3~10%</em> 的平台分红！</p>
                            </div>
                            <div className="bole-table">
                                <div className="bole-table-top">
                                    <h4><img src="/image/bole-new.png" alt=""></img>邀请新人排名</h4>                                     
                                    <ul>
                                    {
                                        ['排 名','伯乐','邀请成果','累计平台分红'].map((item,index)=>{
                                            return(
                                                <li key={index}>{item}</li>
                                            )
                                        })
                                    }
                                    </ul>                               
                                </div>
                                <div className="bole-table-content">
                                    <ul>
                                        {
                                            boleList.map((item,index)=>{
                                                return(
                                                    <li key={index}>
                                                        <div>
                                                            <span>{index < 3 ? (index === 0 ? <img src="/image/icon/bole-01.png" alt=''></img>:(index === 1 ? <img src="/image/icon/bole-02.png" alt=''></img>:<img src="/image/icon/bole-03.png" alt=''></img>)) 
                                                            :index + 1 }</span>
                                                            <span>
                                                               {
                                                                    item.img ? 
                                                                    <i className="table-ava" 
                                                                            style={{backgroundImage:'url('+item.img+')'}}
                                                                    ></i>:                                                                                                                    
                                                                    <i className="table-ava" 
                                                                            style={{backgroundImage:'url(/image/01.png)'}}>                                                                                                                            
                                                                    </i>
                                                                }
                                                                {
                                                                    item.name ?  
                                                                    <p>{item.name}</p> :
                                                                    ''                                                               
                                                                }                                                         
                                                            </span>
                                                            <span>{item.achievement ? item.achievement :'0'}</span>
                                                            <span>{item.bonus ? item.bonus : '0'}</span>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul> 
                                </div>                         
                             </div>
                        </div>                           
                     </div>  
                     <div className="layout" style={{padding:'0 0 20px'}}>
                        <div className="container">
                            <div className="home-content-title">                            
                                <span className="title">我发现的千里马<br></br>共<CountUp
                                        start={0}
                                        end={21}
                                        duration={5}
                                        useEasing
                                        useGrouping 
                                        separator=","  
                                    />位</span>                              
                            </div>
                            <div className="find-bole">
                                <ul>
                                    {
                                        [1,2,3,4,5,6,7,8,9,1,1,2,3,4,5,6,7,8,9,1,1].map((item,index)=>{
                                            return (
                                                <Tooltip placement="topLeft" title="名字" key={index}>
                                                    <li><span><img src="/image/01.png" alt=""></img></span></li>
                                                </Tooltip>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <p className="title-intro">对方每次提交成果，你都将获得 <em>3~10%</em> 的平台分红！</p>
                        </div>
                    </div>                                     
                 </div>                      
                <Footer />
            </div>
        );
    };
    componentDidMount() {
        let that = this;
        window.addEventListener('resize', () => {
            that.setState({
                width:window.innerWidth,                        
            });
        }); 
       // that.setState({inviteUrl:window.location.href});     
       let isIniframe = util.common.getQueryString('isIniframe') || ''; 
       this.props.getISInitFrame(isIniframe);       
       let ukey = window.localStorage.getItem('userKey') ? window.localStorage.getItem('userKey') :''  
       this.setState({userkey:ukey}); 
    }
    //浏览器下载图片 pc端
    downloadImg(imgSrc,name){
        fetch(imgSrc).then(res => res.blob().then(blob => {
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            var filename = name;
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        }))
    }
   
}

export default connect( 
    mapStateToProps,
    { getISInitFrame},    
)(BolePrize);