import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import { getKnowledgeKey,} from '../actions/app';
import './Knowledge.css';
import Footer from '../components/Footer';
import knowledgeData from '../data/data';
     
const mapStateToProps = state => ({
    btnUrl:state.common.btnUrl,
    downloadpath:state.common.downloadpath,    
});
class Knowledge extends Component {  
    constructor(props){
        super(props);
        this.state={
          keywords:'', 
          isSearchList:false, 
        };
    }  
    // input change
    changeinput(e){
        if (e.target.value === '' || e.target.value.match(/^\s+$/g)) {
            message.warning('搜索内容不能为空，或都是空格~，请输入关键字');
            this.setState({isSearchList:false})
        }
        this.setState({keywords: e.target.value.replace(/^\s+$/g, '')});       
    }
    //input onkeyup
    handleSendlistAjax(e){
        if (!e.target.value) {
            return false;
        } 
        if (e.keyCode === 13) {
            console.log('enter')                            
        }
    }
    gotoTry(){ //按钮跳转
        window.location.href = this.props.btnUrl;
    }
    getKnowledgeDetails(key){
      this.props.getKnowledgeKey('sub'+(key+1));
      this.props.history.push('/knowledgeDetails');
    }
    render() {  
        const {keywords,} = this.state;   
        console.log('knowledges',knowledgeData); 
      
        return (
            <div className="homepage">
                 <div className="home-banner download-banner">
                    <div className="container">
                        <h3 className="banner-title-h3">天天打字知识库</h3>
                        <div className="search-box">
                            <input className="search-input" 
                                   type="text" placeholder="输入关键字" 
                                   value={keywords} 
                                   onChange={(e)=>this.changeinput(e)}                                
                                   onKeyUp={(e) => this.handleSendlistAjax(e)}
                             />
                            <i className="search-icon"></i>
                            {/* 搜索列表 */}
                            {/* <div className="search-list">
                                <ul className="search-list-ul">
                                    <li>搜索的关键字</li>
                                    <li>搜索的关键字</li>
                                </ul>
                            </div> */}
                        </div>
                        <p style={{textAlign:'left',letterSpacing:'1px',margin:'10px 0 0'}}>关于考核    关于结算    结算周期    难度系数</p>
                    </div>
                    <div className="banner-btm"></div>
                 </div>
                 <div className="common-content layout knowledge-content">
                     <div className="container">  
                     {
                        knowledgeData && knowledgeData.length ?                                         
                        <div className="knowledge-body">
                           <div className="knowledge-body-title">
                               <h2>天天打字使用手册</h2>
                               <p>提供天天打字平台的使用说明、帮助文档、常见问题等服务，让你快速上手、 高效无忧</p>
                           </div>                          
                            {
                                knowledgeData.map((item,index)=>{
                                    return(
                                        <div key={index} className="knowledge-list">
                                            <h3>{item.title}</h3>
                                            <ul>
                                                {
                                                    item.list.map((item2,index2)=>{
                                                        return(
                                                            <li key={index2} onClick={()=>this.getKnowledgeDetails(index)}>
                                                                <strong>{item2.name}
                                                                {item2.hot ? <i className="hot-icon"></i> :null}
                                                                </strong>
                                                                <span className="space"></span>
                                                                <em>{item2.english}</em>
                                                            </li> 
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div> 
                                    )
                                })
                            }                              
                        </div>:
                        <div className="search-noData" style={{padding:'100px 0'}}>
                            <img src="/image/no-data.svg" alt="" />
                            <p>暂无相关数据</p>
                        </div>
                       }
                     </div>
                 </div> 
                 <div className="home-about volunteer" >                  
                    <div className="container home-volunteer knowledge-bottom">
                        <div className="home-content-title">                          
                            <span className="title">我想成为文献整理者</span>
                        </div>
                        <div className="banner-btn join-btn" onClick={()=>this.gotoTry()}>现在试试</div>
                   </div>
                    <img src="/image/volunteer.svg" alt='' style={{width:'100%'}}></img>                                                                                                       
                </div>   
                <Footer />
            </div>
        );
    };
    componentDidMount() {
        
    }
   
}

export default connect(  
    mapStateToProps,
    {getKnowledgeKey}
)(Knowledge);