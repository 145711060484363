import React, { Component } from 'react';
import './Header.css';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getUserInfo } from '../actions/app';

const mapStateToProps = state => ({
    loginUrl:state.common.loginUrl,
    user:state.auth.user ,
    downloadpath:state.common.downloadpath, 
    inviterKey:state.home.inviterKey,    
});

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {         
            phoneState:false, 
            headerFixed:false                  
        };
        this.handleScroll = this.handleScroll.bind(this);        
    }
    componentDidMount(){ 
        // let isMobile =  util.common.isMobile(); 
        window.addEventListener('scroll', this.handleScroll);
        // if(!isMobile){
        //     window.addEventListener('scroll', this.handleScroll);
        // } 
        // if(window.localStorage.getItem('token') && window.localStorage.getItem('token') !=='underfind'){           
        //    console.log(11);      
        // }              
    }
    componentWillMount() { 
        if(window.localStorage.getItem('token') && window.localStorage.getItem('token') !=='underfind'){
            this.props.getUserInfo(window.localStorage.getItem('token'));         
        } 
    }
    handleScroll(){        
        const fixedTop = 1;
        window.onscroll = () => {             
            let scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop)
            //控制元素块A随鼠标滚动固定在顶部          
            if (scrollTop >= fixedTop) {
                this.setState({ headerFixed: true })
            } else if (scrollTop < fixedTop) {
                this.setState({ headerFixed: false })
            }
        }      
    }
      
    showPhoneMenu(e){
       this.setState({phoneState:true});
    }
    closePhonNav(){
        this.setState({phoneState:false});
    }
    login(){ //登录    
       window.location.href = this.props.loginUrl;      
    }
    // logout(){ //退出 
    //   window.localStorage.clear();   
    //   this.props.history.push('/');      
    // }
    getToadmin(){ //头像跳转 
        window.location.href = this.props.loginUrl;
        // if(window.localStorage.getItem('isIniframe')){
        //     window.location.href='https://ttdazi.qingtime.cn/manage/workingTable?isIniframe='+window.localStorage.getItem('isIniframe')
        // } else{
        //     window.location.href = this.props.loginUrl; 
        // }
        // window.localStorage.removeItem("isIniframe",''); 
    }
    gotoAdmin(){ //进入后台领取任务
        window.location.href = this.props.loginUrl;
        // if(window.localStorage.getItem('isIniframe')){         
        //     window.location.href='https://ttdazi.qingtime.cn/manage/workingTable?isIniframe='+window.localStorage.getItem('isIniframe')
        // } else{            
        //     window.location.href = this.props.loginUrl; 
        // }
        // window.localStorage.removeItem("isIniframe",'');  
    } 
    gotoknowledge(){ //知识库
       window.open('https://www.yuque.com/qingtime/ttdz');
    }
    gotoTeasing(){ //吐槽
       window.open('https://support.qq.com/products/85244');
    }
    register(){//注册
      const {inviterKey} = this.props;
      let url ='https://ttdazi.qingtime.cn/manage/register';
      window.location.href = url+'?inviterKey='+inviterKey;
    }
    render() {
        const { location,user,inviterKey } = this.props;
        let pathname = location.pathname;
        const { phoneState,headerFixed} = this.state;           
        let avatar = user && user.profile && user.profile.avatar ? user.profile.avatar :'/image/ava.png';
        console.log('inviterKey',inviterKey);
        return ( 
            <div className={'app-menu '+ (phoneState ? 'phone':(headerFixed ? 'header-menu active':''))}>        
                <ul className="container">            
                    <li className={`menu-logo`}>
                        <Link to="/"></Link>
                    </li>
                    <li className="phone-logo">
                        <div>
                            <Link to="/"></Link>
                            <img className="phone-nav-close" src="/image/phone/phone-nav-close.svg" alt="" onClick={(e)=>this.closePhonNav(e)}></img>
                        </div>
                    </li>
                    <li className="menu-space"></li>
                    <li className={pathname === '/' ? 'active' : ''}>
                        <Link to="/">首页</Link>
                    </li>
                    {/* <li className={pathname === '/knowledge' ? 'active' : ''}>
                        <Link to="/knowledge">知识库</Link>
                    </li>
                    <li className={pathname === '/teasing' ? 'active' : ''}>
                        <Link to="/teasing">神吐槽</Link>
                    </li> */}
                    <li className="common-class" onClick={()=>this.gotoknowledge()}>
                        <span>知识库</span>
                    </li>
                    <li className="common-class" onClick={()=>this.gotoTeasing()}>
                        <span>神吐槽</span>
                    </li>
                    
                    {/* <li className={pathname === '/rankingPrize' ? 'active' : ''}>
                        <Link to={window.localStorage.getItem('isIniframe') ? '/rankingPrize?isIniframe='+window.localStorage.getItem('isIniframe') :'/rankingPrize'}>排行榜</Link>
                    </li>                    
                    <li className={pathname === '/bolePrize' ? 'active' : ''}>
                        <Link to={window.localStorage.getItem('isIniframe') ? "/bolePrize?isIniframe="+window.localStorage.getItem('isIniframe'):'/bolePrize' }>伯乐榜</Link>
                    </li> */}
                    {/* <li className={pathname === '/rankingPrize' ? 'active' : ''}>
                        <Link to='/rankingPrize'>排行榜</Link>
                    </li> 
                    <li className={pathname === '/bolePrize' ? 'active' : ''}>
                        <Link to="/bolePrize">伯乐榜</Link>
                    </li> */}
                    <li className={'download ' + (pathname === '/download' ? 'active' : '')}>                    
                       <Link to="/download">桌面版</Link> 
                    </li>
                    {/* <li className="login-text">
                        {
                            window.localStorage.getItem('token') && window.localStorage.getItem('token') !=='underfind' ?                                
                            <div className="login-state">
                                {
                                   window.localStorage.getItem('isIniframe') ? 
                                   null:<span className="avatar-img" 
                                   style={{backgroundImage:'url('+avatar+')'}} 
                                   onClick={()=>this.getToadmin()}>
                                </span> 
                                }                       
                                  {
                                     window.localStorage.getItem('isIniframe') ? 
                                     null :
                                     <span onClick={()=>this.gotoAdmin()}>进入</span>
                                  }
                            </div> :
                            <span onClick={()=>this.login()}>登录</span>                      
                        }                            
                    </li>  */}
                    <li className="login-text">
                        {
                            window.localStorage.getItem('token') && window.localStorage.getItem('token') !=='underfind' ?                                
                            <div className="login-state">
                                <span className="avatar-img" 
                                   style={{backgroundImage:'url('+avatar+')'}} 
                                   onClick={()=>this.getToadmin()}>
                                </span>
                                <span onClick={()=>this.gotoAdmin()}>进入</span>                                
                            </div> :
                            <span onClick={()=>this.login()}>登录</span> 
                                                
                        }                            
                    </li>
                    {
                       window.localStorage.getItem('token') && window.localStorage.getItem('token') !=='underfind' ? 
                       null :
                       <li className="login-text">
                          <span onClick={()=>this.register()}>注册</span>                                                  
                       </li>
                    }           
                    <li className="phone-nav-icon" onClick={(e)=>this.showPhoneMenu(e)}></li>
                </ul>
            </div>              
        );
    };
    componentWillReceiveProps(nextProps){
         if (nextProps.location.pathname !== this.props.location.pathname) {
            this.setState({phoneState:false});  
            window.scrollTo(0, 0)          
         }
    }
}

export default connect(
    mapStateToProps,
    {getUserInfo}
   )(withRouter(Header));
