import {
    GET_STORY_THEMES,
    SELECT_STORY_THEME
} from '../actions/app';

const defaultState = {
    themeList:[],
    storyTheme:null,
    // 是否显示上传故事主题界面
    showThemeUpload: false,
};

const story = (state = defaultState, action) => {
    switch (action.type) {
        case GET_STORY_THEMES:
                console.log('action.payload.result',action.payload.result)
                let list = action.payload.result;               
                let defaultThemes = [{
                    _key: 'newYear',
                    name: '阖家团圆',
                    realPath: 'newYear',
                }, {
                    _key: 'smokeFire',
                    name: '烟火',
                    realPath: 'smokeFire',
                }, {
                    _key: 'lanternFestival',
                    name: '元宵',
                    realPath: 'lanternFestival',
                }, {
                    _key: 'spring',
                    name: '春晓',
                    realPath: 'spring',
                }, {
                    _key: 'lotusPond',
                    name: '荷塘',
                    realPath: 'lotusPond',
                }, {
                    _key: 'love',
                    name: '心心相印',
                    realPath: 'love',
                }, {
                    _key: 'qingMing',
                    name: '清明',
                    realPath: 'qingMing',
                }, {
                    _key: 'mayDay',
                    name: '五一',
                    realPath: 'mayDay',
                }, {
                    _key: 'dragon',
                    name: '端午',
                    realPath: 'dragon',
                }, {
                    _key: 'autumn',
                    name: '金秋',
                    realPath: 'autumn',
                }, {
                    _key: 'father',
                    name: '父亲节',
                    realPath: 'father',
                }, {
                    _key: 'mother',
                    name: '母亲节',
                    realPath: 'mother',
                }, {
                    _key: 'village',
                    name: '美丽乡村',
                    realPath: 'village',
                }, {
                    _key: 'classical',
                    name: '古典',
                    realPath: 'classical',
                }, {
                    _key: 'travel',
                    name: '远行',
                    realPath: 'travel',
                }, {
                    _key: 'pearFlower',
                    name: '梨落',
                    realPath: 'pearFlower',
                }, {
                    _key: 'yanPowder',
                    name: '嫣粉',
                    realPath: 'yanPowder',
                }, {
                    _key: 'plum',
                    name: '寒梅',
                    realPath: 'plum',
                }];
                return {
                    ...state,
                    themeList: [...list, ...defaultThemes],
                }
        case SELECT_STORY_THEME:
            return {
                ...state,
                storyTheme: action.theme,
            }
        default:
            return state;
    }
};

export default story;
