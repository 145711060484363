import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu,message, Icon } from 'antd';
import './Knowledge.css';
import Footer from '../components/Footer';
import knowledgeData from '../data/data';
const { SubMenu } = Menu;
const mapStateToProps = state => ({
    knowLedgeKey:state.home.knowLedgeKey,
    
});
class KnowledgeDetails extends Component {  
    rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
    constructor(props){
        super(props);
        this.state={        
          isSearchList:false, 
          openKeys: ['sub1'],   
          typekey:[],    
        };
    } 
    
    componentDidMount(){
        console.log('knowLedgeKey',this.props.knowLedgeKey);
        var arr = [];
        arr.push(this.props.knowLedgeKey);
        this.setState({openKeys:arr})
    }
     // input change
     changeinput(e){
        if (e.target.value === '' || e.target.value.match(/^\s+$/g)) {
            message.warning('搜索内容不能为空，或都是空格~，请输入关键字');
            this.setState({isSearchList:false})
        }
        this.setState({keywords: e.target.value.replace(/^\s+$/g, '')});       
    } 
     //input onkeyup
     handleSendlistAjax(e){
        if (!e.target.value) {
            return false;
        } 
        if (e.keyCode === 13) {
            console.log('enter')                            
        }
    }
    onOpenChange = openKeys => {
        console.log('openKeys',openKeys);
        var arr = [];
        arr.push(openKeys[1]);
        console.log('arr',arr);
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          this.setState({ openKeys });     
        } else {
          this.setState({
            openKeys: latestOpenKey ? [latestOpenKey] : [],
          });
        }
      };
    render() {  
        const {keywords} = this.state;  
        console.log('knowledgeData',knowledgeData);  
        return (
            <div className="homepage">
                 <div className="home-banner download-banner">
                    <div className="container">
                        <h3 className="banner-title-h3">知识库详情</h3>                       
                    </div>
                    <div className="banner-btm"></div>
                 </div>
                 <div className="common-content layout">
                     <div className="container">                        
                        <div className="knowledge-details-body">
                            <div className="knowledge-left">
                                 <div className="search-box">
                                     <input type="text" 
                                            placeholder="输入关键字" 
                                            value={keywords}  
                                            className="search-input"
                                            onChange={(e)=>this.changeinput(e)}                                
                                            onKeyUp={(e) => this.handleSendlistAjax(e)}
                                      />
                                     <i className="search-icon"></i>
                                 </div>
                                 <div className="knowledge-menu">
                                 <Menu
                                        mode="inline"
                                        openKeys={this.state.openKeys}
                                        onOpenChange={this.onOpenChange}                             
                                    >
                                        {
                                           knowledgeData.map((item,index)=>{
                                               return(
                                                    <SubMenu
                                                        key={'sub'+(index+1)}
                                                        title={
                                                            <span>                                                   
                                                                <span className="knowledge-menu-title">{item.title}</span>                                                               
                                                            </span>
                                                        }>                                                  
                                                        {
                                                            item.list.map((item2,index2)=>{
                                                                return(
                                                                    <Menu.Item key={'sub_'+ index + index2}>{item2.name}
                                                                    {item2.hot ? <i className="hot-icon"></i> :null}</Menu.Item>                                                                    
                                                                )
                                                            })
                                                        }                                                                                          
                                                </SubMenu> 
                                               )
                                           }) 
                                        }                                    
                                </Menu>                                
                                 </div>
                            </div>  
                            <div className="knowledge-right">
                                <div className="knowledge-right-body">
                                    <h3 className="knowledge-right-title">关于账号问题</h3>
                                    <p>时光科技研发的天天打字平台账号体系与其旗下的当归App是互通的，您在天天打字平台的所有收益，均可在当归App中提现。建议您扫码下载。</p>
                                    <span>当归——记录生活记录美</span>
                                </div>
                            </div>                    
                        </div>
                     </div>
                 </div>     
                <Footer />
            </div>
        );
    };
   
   
}

export default connect(  
    mapStateToProps
)(KnowledgeDetails);