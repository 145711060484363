import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, } from "react-router-dom";
import { Spin,BackTop } from 'antd';
import { connect } from 'react-redux';
import Header from './components/Header';
import Home from './components/Home';
import Knowledge from './components/Knowledge';
import KnowledgeDetails from './components/KnowledgeDetails';
import Teasing from './components/Teasing';
import RankingPrize from './components/RankingPrize';
import BolePrize from './components/BolePrize';
import Download from './components/Download';
import ThemePanel from './components/panel/ThemePanel';

const mapStateToProps = state => ({
  loading: state.common.loading,
  isIniframe:state.home.isIniframe, 
})

class App extends Component {
  render() {
    const { loading,isIniframe } = this.props;  
    console.log('isIniframe',isIniframe);
    return (
      <Router>
        <div className="app">
          {
            isIniframe == 1 ? null :<Header />
          }
          <BackTop>
              <div className="ant-back-top-inner"><img src="image/icon/backTop-arrow.svg" alt=""></img></div>
          </BackTop>
          <div className="app-content">
            <Route exact path="/" component={Home} /> 
            <Route exact path="/knowledge" component={Knowledge} />
            <Route exact path="/knowledgeDetails" component={KnowledgeDetails} />
            <Route exact path="/teasing" component={Teasing} />
            <Route exact path="/rankingPrize" component={RankingPrize} />
            <Route exact path="/bolePrize" component={BolePrize} /> 
            <Route exact path="/download" component={Download} /> 
            <Route exact path="/themePanel" component={ThemePanel} />          
          </div>
          {loading ? (
            <div className="loading-mask">
              <Spin size="large" />
            </div>
          ) : null}
        </div>
      </Router>
    );
  }
}

export default connect(
  mapStateToProps,
  {},
)(App);
