const knowledgeData = [
    {
        "title":'先后指引',
        "list":[ 
            {'name':'天天打字平台是干什么的？','hot':false,'english':'about'},
            {'name':'为什么选择天天打字？','hot':false,'english':'why'},
            {'name':'天天打字平台的数据安全','hot':false,'english':'safety'},
            {'name':'5分钟带你玩转天天打字','hot':true,'english':'Briefly'},   
        ],        
    },
    {
        "title":'常见问题',
        "list":[
            {'name':'关于账号问题','hot':true,'english':'Account number'},
            {'name':'为何要设置考核环节','hot':true,'english':'Assessment'},
            {'name':'如何认领任务','hot':false,'english':'Claim task'},
            {'name':'关于任务的难度系数','hot':false,'english':'Degree of Difficulty'},  
            {'name':'关于任务的结算方式','hot':true,'english':'Settlement method'},
            {'name':'关于任务的结算周期','hot':true,'english':'Settlement cycle'},
            {'name':'任务的收益会受哪些方面的影响','hot':false,'english':'Influence'},
            {'name':'没有按时完成任务会怎么样','hot':false,'english':'Task extension'}, 
            {'name':'为什么要下载桌面端','hot':false,'english':'Download desktop'},
            {'name':'排行榜是什么','hot':true,'english':'Ranking List'},
            {'name':'如何才能上排行榜','hot':false,'english':'Top ranking'},
            {'name':'关于推荐有奖问题','hot':false,'english':'Recommended prize'},  
            {'name':'如何提现','hot':true,'english':'Cash withdrawal'},      
       ], 
    },
    {
        "title":'文章录入',
        "list":[
            {'name':'文章录入界面介绍','hot':false,'english':'Interface introduction'},
            {'name':'文章关联影像文件','hot':true,'english':'Link image'},
            {'name':'其他注意事项','hot':true,'english':'Matters needing attention'},
              
       ],  
    },
    {
        "title":'谱系录入',
        "list":[
            {'name':'谱系录入界面介绍','hot':false,'english':'Interface introduction'},
            {'name':'谱系需要录什么内容','hot':false,'english':'Content'},
            {'name':'什么时候需要创建分支','hot':false,'english':'Create branch'},
            {'name':'不确定的问题记录','hot':false,'english':'Problem record'},
            {'name':'符号使用规则','hot':true,'english':'Symbol'},
            {'name':'生僻字处理方式','hot':false,'english':'Rare words'},
            {'name':'字辈录入规则','hot':true,'english':'Character generation'},
            {'name':'世系图录入规则','hot':true,'english':'Genealogy map'},
            {'name':'世系图快捷键操作','hot':false,'english':'Shortcut keys'},
            {'name':'世系图人名节点录入规则','hot':true,'english':'Node entry'},
            {'name':'基本信息栏录入规则','hot':true,'english':'Essential information'},
            {'name':'文章关联影像文件','hot':true,'english':'Link image'},          
            {'name':'基本信息中地址栏的录入规则','hot':false,'english':'Address rule'},
            {'name':'基本信息中日期录入规则','hot':false,'english':'Date rule'},
            {'name':'天干地支表','hot':false,'english':'Heavenly stems and Earthly Branches'},               
       ], 
    },
   
]  
export default  knowledgeData 