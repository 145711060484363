import api from './services/Api';

import {
    ASYNC_START,
    ASYNC_END,
    LOGIN,
    LOGOUT,
    REGISTER,
}
    from './actions/app';

const promiseMiddleware = store => next => action => {
    if (isPromise(action.payload)) {
        store.dispatch({ type: ASYNC_START, noLoading: action.noLoading, subtype: action.type });

        const currentView = store.getState().viewChangeCounter;
        const skipTracking = action.skipTracking;

        action.payload.then(
            res => {
                const currentState = store.getState();
                if (!skipTracking && currentState.viewChangeCounter !== currentView) {
                    return;
                }
                if (parseInt(res.statusCode, 10) === 200) {
                    action.payload = res;
                    store.dispatch({ type: ASYNC_END, promise: action.payload });
                    store.dispatch(action);
                }
                else {
                    action.error = true;
                    action.payload = res;
                    // alert(res.msg);
                    store.dispatch({ type: ASYNC_END });
                    store.dispatch(action);
                }
            },
        );
        return;
    }

    next(action);
};

const localStorageMiddleware = store => next => action => {
    if (action.type === LOGIN) {
        if (!action.error) {
            window.localStorage.setItem('TOKEN', action.payload.token);
            api.setToken(action.payload.token);
        }
    } else if (action.type === REGISTER) {
        window.localStorage.setItem('TOKEN', action.payload.data.token);
        api.setToken(action.payload.data.token);
    }
    else if (action.type === LOGOUT) {
        window.sessionStorage.clear();
        api.setToken('');
    }

    next(action);
};

function isPromise(v) {
    return v && typeof v.then === 'function';
}


export { promiseMiddleware, localStorageMiddleware };