import api from '../services/Api';

import { LOGIN, REGISTER, GET_USER_INFO, LOGOUT, } from '../actions/app';
const defaultState = {
    user: null,
};

const auth = (state = defaultState, action) => {
    switch (action.type) {
        // 登陆
        case LOGIN:
            if (!action.error) {
                return {
                    ...state,
                    user: action.payload,
                };
            } else {
                return state;
            }
        // 登出
        case LOGOUT:
            if (window.QC) {
                window.QC.Login.signOut();
            }
            // window.sessionStorage.clear();           
            window.localStorage.clear();
            api.setToken('');
            return {
                ...state,
                user: null,
            }
        case REGISTER:
            if (!action.error) {
                return {
                    ...state,
                    user: action.payload.data,
                };
            } else {
                return state;
            }
        // 获取用户信息
        case GET_USER_INFO:
            if (!action.error) {
                const res = action.payload;
                return {
                    ...state,
                    user: res.result,
                };
            }
            else {
                if (window.QC) window.QC.Login.signOut();
                // window.sessionStorage.clear();
                api.setToken('');
                // action.history.push('/');
                return state;
            }      
        default:
            return state;
    }
};

export default auth;
