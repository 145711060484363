import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Footer.css';

const mapStateToProps = state => ({
      
});

class Footer extends Component {
    openNewWindow(){
      window.open('http://beian.miit.gov.cn/');  
    }
    render() {      
        return (
                <div className="home-footer">
                   <div className="container">
                       <div className="home-footer-box clearfix">
                            <div className="home-footer-left">
                                <p>©2019 江苏时光信息科技有限公司 Qingtime All Rights Reserved <span onClick={()=>this.openNewWindow()} style={{cursor:'pointer'}}>苏ICP备15006448号</span></p>                              
                            </div>
                            <div className="home-footer-middle">
                                <p>hello@qingtime.com</p>                            
                            </div>                            
                       </div>                       
                   </div>
                </div>
        );
    };
}

export default connect(mapStateToProps)(Footer)