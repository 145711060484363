import {
    ASYNC_START, ASYNC_END,
    DOWNLOAD_TODESK,
} from '../actions/app';

const defaultState = {
    loading: false,
    waiting: false,
    endTime: Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30,
    loginUrl:'https://ttdazi.qingtime.cn/manage/workingTable',
    // loginUrl:'http://192.168.1.107:8080/login',
    btnUrl:'https://ttdazi.qingtime.cn/manage/workingTable',    
    // downloadpath:'http://192.168.1.101:8093',
    // downloadpath:'https://ttdazidata.qingtime.cn',
    downloadpath:'http://cdn-icare.qingtime.cn',
    appPath:'',
};

const common = (state = defaultState, action) => {
    switch (action.type) {
        case ASYNC_START:
            return {
                ...state,
                loading: action.noLoading ? false : true,
                waiting: true,
            };
        case ASYNC_END:
            return {
                ...state,
                loading: false,
                waiting: false,
            };
        case DOWNLOAD_TODESK:
            if (!action.error) {
                console.log('action.payload',action.payload);
                return {
                    ...state,
                    appPath: action.payload.appPath,
                };
            } else {
                return state;
            }
        default:
            return state;
    }
};

export default common;
