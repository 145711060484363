import React, { Component } from 'react';
// import { Button, } from '../form/Form';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { getStoryThemes,selectTheme} from '../../actions/app';
import './panel.css';
import util from '../../services/Util';

const mapStateToProps = state => ({   
    themeList: state.story.themeList,
    storyTheme: state.story.storyTheme,
});

class ThemePanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDelDialog: false,
        };
        this.switchDelDialog = this.switchDelDialog.bind(this);
        this.handleConfirmDel = this.handleConfirmDel.bind(this);
        this.cancelSelectTheme = this.cancelSelectTheme.bind(this);
    }

    switchDelDialog() {
        this.setState((preState) => (
            { showDelDialog: !preState.showDelDialog, }
        ));
    }

    handleConfirmDel(key) {
        const { deleteStoryTheme } = this.props;
        deleteStoryTheme(key);
        this.setState({ showDelDialog: false });
    }

    cancelSelectTheme() {
        const { cancelSelectTheme, switchThemePanel } = this.props;
        cancelSelectTheme();
        switchThemePanel();

    }

    render() {
        const {  user, setTheme,  themeList, toEditStoryTheme, storyTheme, selectTheme } = this.props;
        console.log('render-storyTheme',storyTheme);
        return (
            <div className="music-panel-container">
                <div className="music-panel story-theme-panel">
                    <div className="music-panel-head">设置主题</div>
                    <div className="music-panel-body">
                        <ul>
                            {/* 公开的主题 */}
                            <div className="story-theme-type">主题</div>
                            {/* <li className="noMusic" onClick={selectTheme.bind(this, null)}>
                                默认
                                {storyTheme === null ? <i className="music-check"></i> : null}
                            </li> */}
                            {themeList.map((theme, index) => (
                              
                                    <li key={index} 
                                        onClick={selectTheme.bind(this,theme)}                                       
                                    >
                                        <i className="music-icon" style={{ backgroundImage: `url(/storyTheme/${theme.realPath}/cover.png)` }}
                                        ></i>
                                        <span className="music-name">{theme.name}</span>
                                        {storyTheme && storyTheme.realPath === theme.realPath ? <i className="music-check"></i> : null}
                                    </li> 
                            ))}

                            {/* 我的主题 */}
                            {/* <div className="story-theme-type"></div>
                            {themeList.map((theme, index) => (
                                user._key === theme.userKey ?
                                    <li key={index}
                                        onClick={selectTheme.bind(this, theme)}
                                    >
                                        <i className="music-icon" style={{ backgroundImage: `url(/storyTheme/${theme.realPath}/cover.png)` }}
                                        ></i>
                                        <span className="music-name">{theme.name}</span>
                                        <div className="theme-action story-theme-action">
                                            <span className="edit-theme" onClick={toEditStoryTheme.bind(this, theme)}>编辑</span>
                                            <span onClick={this.switchDelDialog}>删除</span>
                                        </div>
                                        {storyTheme && storyTheme.realPath === theme.realPath ? <i className="music-check"></i> : null}
                                    </li> : null
                            ))} */}
                        </ul>
                    </div>
                    <div className="music-panel-foot">                       
                        <div className="theme-common-btn theme-del-btn">取消</div>
                        <div className="theme-common-btn theme-save-btn">保存</div>
                    </div>
                   
                </div>
            </div>);
    }

    componentDidMount() {
        const { getStoryThemes } = this.props;
        let token = util.common.getQueryString('token') || '';
        getStoryThemes(token);
    }
}

export default connect(  
    mapStateToProps,
    {getStoryThemes,selectTheme}
)(ThemePanel);
